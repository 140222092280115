:root {
  --primary: #0d3f8a;
  --secondary: rgb(172, 172, 172);
  --success: rgb(28, 146, 44);
  --danger: rgb(243, 0, 0);
  --warning: #ffa630;
  --light: rgb(246, 249, 254);
  --dark: rgb(34, 34, 34);
  --muted: rgb(94, 106, 114);
  --white: rgb(255, 255, 255);
  --border: rgb(0, 0, 0, 0.1);
  --skyblue: #56bced;
  --blue: #00a7ea;
  --linear-gradient: linear-gradient(-160deg, #0d3f8a 0, #00a7ea 99%);
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
}

.btn-outline-warning {
  --bs-btn-color: var(--warning) !important;
  --bs-btn-border-color: var(--warning) !important;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: var(--warning) !important;
  --bs-btn-hover-border-color: var(--warning) !important;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: var(--warning) !important;
  --bs-btn-active-border-color: var(--warning) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--warning) !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--warning) !important;
  --bs-gradient: none;
}
.btn {
  border-radius: 3px !important;
}
body {
  /* font-family: "Open Sans", sans-serif !important; */
  color: var(--dark);
  font-size: 15px;
}
/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
h1,
.h1,
h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--dark);
  font-weight: 600 !important;
}

/* Style for h1 */
h1,
h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

/* Style for h2 */
h2,
h2 {
  font-size: 28px;
  margin-bottom: 8px;
}

/* Style for h3 */
h3,
h3 {
  font-size: 24px;
  margin-bottom: 6px;
}

/* Style for h4 */
h4,
h4 {
  font-size: 20px;
  margin-bottom: 4px;
}

/* Style for h5 */
h5,
h5 {
  font-size: 17px;
  margin-bottom: 2px;
}

/* Style for h6 */
h6,
.h6 {
  font-size: 15px;
  margin-bottom: 2px;
}
p,
.p {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--dark);
  font-weight: 400;
}
p:last-child {
  margin-bottom: 0;
}
a {
  color: var(--primary);
  text-decoration: none !important;
}
img {
  display: block;
  width: 100%;
}
.content-body {
  min-height: 60vh;
}
.bold {
  font-weight: bold;
}
input, textarea {
  border-radius: 4px !important;
}
/*--------------------------------------------------------------
# Section
--------------------------------------------------------------*/
section {
  padding: 70px 0;
}

/*--------------------------------------------------------------
# Listing
--------------------------------------------------------------*/
ul,
ol {
  margin: 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Topbar
--------------------------------------------------------------*/
.topbar {
  font-size: 0.85rem;
  background-color: var(--primary);
  color: var(--white);
  padding: 8px 0;
  display: flex;
}
.topbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.topbar ul li {
  margin-right: 10px;
}
.topbar ul li:last-child {
  margin-right: 0px;
}
.topbar ul li b {
  margin-right: 5px;
}
.topbar ul li,
.topbar ul li a {
  color: var(--white);
  text-decoration: none;
}
@media only screen and (max-width: 1024px) {
  .topbar {
    display: none !important;
  }
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/
.mainNav {
  position: fixed;
  top: 0;
  z-index: 1050;
  width: 100%;
}
.hidden {
  display: none;
}
.navbar-brand {
  width: 130px;
}
.navbar-expand-lg .navbar-nav .nav-link.btn {
  color: var(--white);
  background-color: var(--bs-orange);
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.navbar .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px;
  font-size: 15px;
  font-weight: 600;
  color: var(--dark);
  white-space: nowrap;
  text-transform: uppercase;
  transition: 0.3s;
  position: relative;
  margin-right: 15px;
  border-top: 2px solid transparent;
  line-height: 3rem;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.navbar-nav .nav-link:hover {
  color: var(--light) !important;
  border-top: 2px solid var(--bs-orange);
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-orange) !important;
}
.navbar-nav .nav-link.btn.active,
.navbar-nav .nav-link.btn.show {
  color: var(--light) !important;
}

.transparent {
  background-color: transparent;
}
.transparent.navbar .nav-link {
  color: var(--light);
}

/*--------------------------------------------------------------
# Dropdown Menu
--------------------------------------------------------------*/

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border-radius: 0;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--primary) !important;
  text-decoration: none;
  background-color: var(--white) !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  transition: background-color 0.5s ease;
}
.dropdown-item {
  padding: 8px 20px !important;
  font-weight: 400 !important;
}

/*--------------------------------------------------------------
# Header Slider
--------------------------------------------------------------*/
.header-slider .carousel-caption {
  right: 0;
  left: 0;
  top: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
}
.header-slider .carousel-caption h1 {
  font-weight: 500 !important;
  color: #fff;
  margin-bottom: 25px;
}
@media (min-width: 1380px) {
  .header-slider .carousel-caption h1 {
    font-size: 3.7rem !important;
  }
}

.header-slider .carousel-caption a {
  margin-right: 15px;
}
.header-slider .carousel-caption a:last-child {
  margin-right: 0px;
}
.header-slider .carousel-caption p {
  font-size: 1.5rem;
  color: #fff;
}
.header-slider .carousel-inner {
  height: 90vh;
}
.header-slider .carousel-item {
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
.header-slider .carousel-caption .btn {
  text-transform: uppercase;
  font-weight: 500;
}
.header-slider .carousel-caption .btn i,
.header-slider .carousel-caption .btn svg {
  font-size: 12px;
  margin-left: 5px;
}
.header-slider .carousel-caption .btn-light:hover {
  color: var(--bs-white);
  background-color: var(--bs-orange);
  border-color: var(--bs-orange);
}
/*--------------------------------------------------------------
# Inner Header
--------------------------------------------------------------*/
.innerHeader {
  padding: 150px 0 70px 0;
  background-size: cover;
}
.innerHeader h1 {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: var(--white);
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer {
  font-size: 0.85rem;
  background-color: var(--primary);
  color: var(--white);
}

footer .footer-body {
  padding: 60px 0 40px 0;
}
footer .footer-body h5 {
  text-transform: uppercase;
}
footer .footer-body h5 img {
  width: 120px;
}
footer .footer-body p {
  color: var(--white);
}
footer .footer-body ul {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
footer .footer-body ul li b {
  margin-right: 10px;
}
footer .footer-body ul li,
footer .footer-body ul li a {
  color: var(--white);
  text-decoration: none;
  margin-bottom: 5px;
}
footer .footer-body ul li a:hover {
  text-decoration: underline;
}
footer .footer-body ul li.email{
  text-transform: lowercase;
}
footer .company img {
  width: 60%;
  margin-bottom: 10px;
}
footer .copyright {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
}
footer .copyright a {
  color: var(--white);
  text-decoration: none;
}
/*--------------------------------------------------------------
# Social Media
--------------------------------------------------------------*/
.social {
  list-style-type: none;
  margin-bottom: 0;
}
.social li {
  float: left;
  margin-right: 5px !important;
  padding: 2px;
  border: 1px solid var(--white);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 4px;
}
.social li:hover {
  background-color: var(--danger);
}
.social li a {
  color: var(--white);
  text-decoration: none !important;
  margin-bottom: 0 !important;
}
/*--------------------------------------------------------------
# Card
--------------------------------------------------------------*/
.card {
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}
.card:hover{
  border:2px solid var(--bs-orange) !important
}
/*--------------------------------------------------------------
# Product List
--------------------------------------------------------------*/
.productsList {
  position: relative;
  z-index: 2;
  padding: 0;
  margin-top: -148px;
}
.productsList .card .card-header {
  padding: 15px;
  background-color: var(--bs-orange);
  color: var(--light);
  border-radius: 0;
}
.productsList .card .card-header .card-title,
.productsList .card .card-header .card-subtitle {
  width: auto;
}
.productsList .card .card-body {
  background-color: var(--light);
}
/*--------------------------------------------------------------
# Home About Us | Company
--------------------------------------------------------------*/
.whyUsHome {
  background-image: url(../src/img/bg1.jpg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  padding-bottom: 0;
}
.whyUsHome .content {
  padding-top: 70px;
}
.whyUsHome .ceoimage {
  text-align: center;
}
.whyUsHome .ceoimage img {
  max-width: 80%;
  margin: 0 auto;
}
/*--------------------------------------------------------------
# Sectin Heading
--------------------------------------------------------------*/
.heading{
  margin-bottom: 20px;
}
.heading h1::after {
  content: "";
  border-bottom: 3px solid var(--bs-orange);
  width: 50px;
  display: block;
}
.heading.white h1::after {
  content: "";
  border-bottom: 3px solid var(--bs-white);
  width: 50px;
  display: block;
}
.heading.center h1::after {
  margin: 0 auto;
}

/*--------------------------------------------------------------
# Our Businesses
--------------------------------------------------------------*/
.businesses {
  position: relative;
  width: 100%;
  background-image: url("img/bg5.jpg");
  height: 100%; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.businesses::after, .businesses::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.businesses::after {
  background-color: rgb(13, 63, 138, 0.8);
}
.businesses .content{
  position: relative;
  z-index: 1;
}
/*--------------------------------------------------------------
# Product List
--------------------------------------------------------------*/
.productList .card-body p{
  margin-bottom: 0;
}
.productList .card-body .card-title{
  font-size: 1.05rem;
}
.productList a, .productList a i, .productList a svg{
  color: var(--bs-black);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.productList a:hover, .productList a:hover i, .productList a:hover svg{
  color: var(--bs-orange);
}
.productList a i, .productList a svg{
  margin-bottom: 0;
  font-size: 12px;
}
/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients-slider {
  padding: 15px 0;
  text-align: center;
}

.clients img,
.clients-slider img {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}
.clients .card{
  text-align: center;
  border:1px solid var(--border) !important;
}
.clients img{
  max-width: 75%;
}
.clients-slider img {
  max-width: 75%;
  filter: grayscale(100%);
}
.clients-slider img:hover {
  filter: none;
}

.clients img:hover,
.clients-slider img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 60%;
  }
  .clients-slider img {
    max-width: 80%;
  }
}
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services{
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.services .intro{
  background-color: var(--bs-orange);
  padding:70px 8% ;
}
.services .intro p{
  color: var(--bs-white);
}
.services .service-list{
  background-color: var(--bs-orange);
  padding:70px 8% ;
}
.services .service-list {
  position: relative;
  background-image: url("img/about.webp");
  height: 100%; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.services .service-list::after, .services .service-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.services .service-list::after {
  background-color: rgb(13, 63, 138, 0.8);
}
.services .service-list .content{
  position: relative;
  z-index: 1;
}
.services .service-list .content p{
  color: var(--white);
}
.services .service-list .item{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.services .service-list .item:hover{
  background-color: rgb(13, 63, 138, 0.8);
}
.services-item .card{
  height: 100%;
}
.services-item ul{
list-style-type: disc;
margin: 0;
padding-left: 20px;
}
.services-item ul li{
  margin-bottom: 5px;
}
.services-item .item .card:hover{
  border:2px solid var(--bs-orange) !important
}
/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.teamslider{
  position: relative;
  background-image: url("img/bg3.jpg");
  height: 100%; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
}
.teamMember{
  padding: 0 0.5rem;
}
/*--------------------------------------------------------------
# Our Success Stories
--------------------------------------------------------------*/
.successStory {
  position: relative;
  width: 100%;
  background-image: url("img/bg2.jpg");
  height: 100%; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
}

.successStory .content{
  position: relative;
  z-index: 1;
}
.successStory .content .card-body p{
  margin-bottom: 0;
}
.successStory .content .card-body .card-title{
  font-size: 1.05rem;
}
.successStory .content a, .successStory .content a i, .successStory .content a svg{
  color: var(--bs-white);
  background-color: var(--bs-orange);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.successStory .content a:hover, .successStory .content a:hover i, .successStory .content a:hover svg{
  color: var(--bs-white);
}
.successStory .content a i, .successStory .content a svg{
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Quich Access
--------------------------------------------------------------*/
.quichAccess {
  position: relative;
  width: 100%;
  background-image: url("img/bg6.jpg");
  height: 100%; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.quichAccess::after, .quichAccess::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.quichAccess::after {
  background-color: rgb(13, 63, 138, 0.8);
}
.quichAccess .content{
  position: relative;
  z-index: 1;
}
.quichAccess .content p{
  color: var(--bs-white);
}
/*--------------------------------------------------------------
# Counter
--------------------------------------------------------------*/
.counter {
  position: relative;
  width: 100%;
  background-image: url("img/bg8.jpg");
  height: 100%; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.counter::after, .counter::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.counter::after {
  background-color: rgb(13, 63, 138, 0.8);
}
.counter.content{
  position: relative;
  z-index: 1;
}
.counter .content{
  position: relative;
  z-index: 1;
}
.counter .item{
text-align: center;
}
.counter .item i, .counter .item svg {
font-size: 40px;
color: #fff;
margin-bottom: 1rem;
}
.counter .item:nth-child(2) h3::after {
  content: '+';
}
.counter .item:nth-child(3) h3::after {
  content: '%';
}
.counter .item h3{
color: #fff;
padding-bottom: 3px;
margin: 0;
}
.counter .item p {
color: #fff;
font-size: 20px;
margin-bottom: 0;
padding: 0;
}

.btn{
  text-transform: uppercase;
  font-weight: bold;
}
.btn i, .btn svg{
  font-size: 12px;
  margin-bottom: 0;
}
.btn-orange{color: var(--bs-white) !important;
  background-color: var(--bs-orange) !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background-color: var(--bs-orange) !important;
  color: var(--white) !important;
}
.nav-tabs .nav-link{
  color: var(--bs-body-color);
  padding-left: 1rem;
  padding-right: 1rem;
}
.nav-tabs {
  display: flex;
  justify-content: center;
}
.tab-content{
  padding: 1rem;
  border: 1px solid var(--border) !important;
  border-top: 0 !important;
}
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about a.btn, .about a.btn i, .about a.btn svg{
  color: var(--bs-white);
  background-color: var(--bs-orange);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.about a.btn:hover, .about a.btn:hover i, .about a.btn:hover svg{
  color: var(--bs-white);
}
.about a.btn i, .about a.btn svg{
  margin-bottom: 0;
}
.about .productsList{
  margin-top: 2rem;
}

/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
@media only screen and (min-width: 992px) {
  .locations .col{
    width: 20%;
    border-right: 1px solid var(--border);
  }
  .locations .col:last-child{
    border-right: 0;
  }
  
}
.areas{
  border: 1px solid var(--bs-orange);
  text-align: center;
  line-height: 2rem;
  font-weight: bold;
  color: var(--bs-orange);
  margin: 5px 13px ;
}
.contactForm .btn-submit{
  color: var(--bs-white);
  background-color: var(--bs-orange);
  text-transform: uppercase;
}
.contactForm .btn-submit:hover{
  color: var(--bs-white);
  background-color: var(--bs-orange);
  text-transform: uppercase;
}
.contact .access span{
  background-color: var(--bs-orange);
  color: var(--bs-white);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 4px;
}
.contactbg{
  background-image: url(../src/img/bg1.jpg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  padding-bottom: 0;
}
/*--------------------------------------------------------------
# Projects
--------------------------------------------------------------*/
.projects .card{
  height: 100%;
}